import React from "react";
import {progetti} from "../assets/data/progetti.js";
import {Box, Container, Stack, Typography} from "@mui/material";

export const Progetti = () => {
    const [selectedProject, setSelectedProject] = React.useState({})

    const selectProject = (id) => {
        if (id === "") {
            setSelectedProject({})
        } else {
            let p = progetti.filter(p => p.id === id)[0]
            setSelectedProject(p)
        }
    }

    return (
        <Stack sx={{height:'max(40rem, 100vh)'}} justifyContent={'center'} id={"projects"}>
            <Box sx={{width:'min(45rem, 100%)', margin:'auto'}}>
                <Stack direction={'row'} sx={{flexWrap: 'wrap', zIndex: '10'}}
                       justifyContent={'center'}>
                    {
                        progetti.map((progetto) =>
                            <Stack key={progetto.id}
                                   alignItems={'center'}
                                   sx={{
                                       pl: 1.5,
                                       py: 0.5,
                                       opacity: (Object.keys(selectedProject).length > 0 && selectedProject.id !== progetto.id) ? 0.5 : 1,
                                       transition: 'opacity 0.5s ease-in-out',
                                   }}
                                   direction={'row'}>
                                <Box sx={{pr: 1.5, cursor: 'pointer', textDecoration: 'none', color:'black'}}
                                     component={progetto.preview ? 'a' : 'span'}
                                     href={`/projects/${progetto.id}`}
                                     onMouseEnter={() => selectProject(progetto.id)}
                                     onMouseLeave={() => selectProject("")}>
                                    <Typography variant={'h4'}>
                                        {progetto.name}
                                    </Typography>
                                </Box>
                                <Box sx={{width:'max(2rem, 2vw)', pt:1}}>
                                    <img style={{width:'100%'}} src={require('../assets/images/shared/divider.png')}/>
                                </Box>
                            </Stack>
                        )}
                </Stack>
                <Box sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    zIndex: '-1',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0
                }}>
                    <Container maxWidth={'md'}
                        sx={{
                            width: '100%',
                            aspectRatio:"3/2",
                            zIndex: 'inherit',
                            opacity: Object.keys(selectedProject).length > 0 ? 0.9 : 0,
                            transition: 'opacity 0.5s ease-in-out',
                        }}>
                        {(Object.keys(selectedProject).length > 0 && selectedProject?.preview) &&
                            <img style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                 alt={'cover'}
                                 src={require(`../assets/images/projects/${selectedProject?.id}/${selectedProject?.preview}`)}/>
                        }
                    </Container>
                </Box>
            </Box>
        </Stack>
    )
}
