import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Main} from "../layouts/Main";
import {progetti} from "../assets/data/progetti.js";
import {
    Box,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";

export const Progetto = () => {
    const {id} = useParams()
    const [progetto, setProgetto] = React.useState({})
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const Credits = (progetto) => {
        return(
            <Box sx={{pt: {xs:0, md:2.5}, mt:{xs:'-0.2rem', md:'auto'}, ml:{xs:'0.2rem', md:0}}}>
                <Typography sx={{pb: {xs:5, md:3}}} variant={'h6'} fontWeight={'300'} lineHeight={1.2}>
                    {progetto.location}
                    <br/>
                    {progetto.projectType}
                </Typography>
                <Typography sx={{pb:3}} fontWeight={'300'}>
                    {progetto.photographer}
                    <br/>
                    {progetto.styleStudio}
                </Typography>
            </Box>
        )
    }

    useEffect(() => {
        setProgetto(progetti.filter(p => p.id === id)[0])
    },[id])

    return (
        <Main page={'progetto'}>
            <Box sx={{ py:6,pt:16, px: {xs: 0}}}>
                {Object.keys(progetto).length > 0 &&
                    <Grid container spacing={{xs:3, md:2}}>
                        <Grid item xs={12} md={7.5}>
                            <Box>
                                <img style={{width:'100%'}} src={require(`../assets/images/projects/${progetto.id}/${progetto.cover}`)} alt={progetto.name}/>
                            </Box>
                            <Stack direction={'row'} justifyContent={'space-between'} sx={{py:1}}>
                                <Typography variant={'h5'}>
                                    {progetto.name}
                                </Typography>
                                <Typography variant={'h5'}>
                                    {progetto.year}
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} justifyContent={'end'} sx={{pt:2}}>
                                <Box sx={{width: {xs:'100%', md:'45vw'}}}>
                                    <ImageList
                                        sx={{ width: '100%'}}
                                        variant="quilted"
                                        cols={2}
                                        gap={16}
                                    >
                                        {progetto.images.map((item) => (
                                            <ImageListItem key={item.img} cols={item.cols || 1}>
                                                <img
                                                    src={require(`../assets/images/projects/${progetto.id}/${item.img}`)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>

                                </Box>
                            </Stack>
                            {md && <Credits {...progetto}/>}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container sx={{mt:{xs:'-1.4rem', md:'0'}}}>
                                <Grid sx={{pt: {xs:0, md:20}, pr:{xs:1, md:0}}} item xs={6} md={12}>
                                    {progetto.vertical && <img style={{width: '100%'}}
                                          src={require(`../assets/images/projects/${progetto.id}/${progetto.vertical}`)}
                                          alt={progetto.name}/>}
                                </Grid>
                                <Grid item xs={6} md={12} sx={{py: {xs:0, md:2}, pl:{xs:0.5, md:0}}}>
                                    {progetto.mentioned && <Typography variant={'h6'}>
                                        + Mentioned on <a style={{color:'black', textDecoration:'underline'}} href={progetto.link_mention} target={'_blank'}>Platform</a>
                                    </Typography>}
                                    {!md && <Credits {...progetto}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Main>
    )
}