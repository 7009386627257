import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Progetto} from "./pages/Progetto";

function App() {
    return (
        <div>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/projects/:id'} element={<Progetto/>}/>
            </Routes>
        </div>
    );
}

export default App;
