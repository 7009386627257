export const progetti = [
    {
        id: "villaBardellini",
        name: "Villa Bardellini",
        description: "",
        url: "",
        year: "2020-2023",
        mentioned: false,
        location:"Residenza Privata, Lerici",
        projectType:"Interior Design project",
        photographer:"Ph. Studio Blend",
        styleStudio:"",
        preview: "villabardellini_1_orizzontale.jpg",
        cover: "villabardellini_1_orizzontale.jpg",
        vertical:"villabardellini_2_verticale.jpg",
        images: [
            {img:"villabardellini_3_verticale.jpg", cols: 1},
            {img:"villabardellini_4_verticale.jpg", cols: 1},
            {img:"villabardellini_5_verticale.jpg", cols: 1},
            {img:"villabardellini_6_verticale.jpg", cols: 1},
            {img:"villabardellini_7_verticale.jpg", cols: 1},
            {img:"villabardellini_8_verticale.jpg", cols: 1},
        ]
    },
    {
        id: "hnHeadquarter",
        name: "HN Headquarter",
        description: "",
        url: "",
        year: "2023",
        mentioned: true,
        link_mention:"/documents/PLT44-studio blend.pdf",
        location:"Palazzo Rucellai, Firenze",
        projectType:"Interior Design project",
        photographer:"Ph. Thomas Pagani",
        styleStudio:"Styling Studio Blend",
        preview: "01.jpg",
        cover: "01.jpg",
        vertical:"02.jpg",
        images: [
            {
                img: "03.jpg",
                cols: 1
            },
            {
                img: "04.jpg",
                cols: 1
            },
            {
                img: "05.jpg",
                cols: 1
            },
            {
                img: "06.jpg",
                cols: 1
            },
        ]
    },
    {
        id: "leGrange",
        name: "Le Grange",
        description: "",
        url: "",
        year: "2019 - On going",
        mentioned: false,
        location:<>Residenza Privata
            <br/>
            Champlas Seguin, Torino</>,
        projectType:<>
            Architecture project
            <br/>
            Interior Design project
        </>,
        photographer:"Exterior renders Alessandro Consonni",
        styleStudio:"Interior renders Yuri Meroni",
        preview: "00_legrange_copertina_mouseover.jpg",
        cover: "legrange_1_orizzontale.jpg",
        vertical:"legrange_2_verticale.jpg",
        images: [
            {img:"legrange_3_verticale.jpg", cols: 1},
            {img:"legrange_4_verticale.jpg", cols: 1},
            {img:"legrange_5_orizzontale.jpg", cols: 2},
            {img:"legrange_6_orizzontale.jpg", cols: 2},
            {img:"legrange_7_orizzontale.jpg", cols: 2},
            {img:"legrange_8_orizzontale.jpg", cols: 2},
        ]
    },
    {
        id: "villaMontoro",
        name: "Villa Montoro",
        description: "",
        url: "",
        year: "2022 - On going",
        mentioned: false,
        location:<>Residenza Privata
            <br/>
            Colle di Val D’Elsa, Siena</>,
        projectType:"Interior Design project",
        photographer:"Renders Gianmarco Marongiu e Giacomo Congiu",
        styleStudio:"",
        preview: "villamontoro_1_orizzontale.jpg",
        cover: "villamontoro_1_orizzontale.jpg",
        vertical:"villamontoro_2_verticale.jpg",
        images: [
            {img:"villamontoro_3_orizzontale.jpg", cols: 2},
            {img:"villamontoro_4_orizzontale.jpg", cols: 2},
            {img:"villamontoro_5_orizzontale.jpg", cols: 2},
        ]
    },
    {
        id: "atticoLR",
        name: "Attico LR",
        description: "",
        url: "",
        year: "2020-2023",
        mentioned: false,
        location:"Attico, Torino",
        projectType:"Interior Design project",
        photographer:"",
        styleStudio:"Renders Yuri Meroni",
        preview: "atticolr_1_orizzontale.jpg",
        cover: "atticolr_1_orizzontale.jpg",
        vertical:"atticolr_2_verticale.jpg",
        images: [
            {img:"atticolr_3_orizzontale.jpg", cols: 2},
            {img:"atticolr_4_orizzontale.jpg", cols: 2},
            {img:"atticolr_5_verticale.jpg", cols: 1}
        ]
    },
    {
        id: "brajaFarm",
        name: "Braja Farm",
        description: "",
        url: "",
        year: "2020",
        mentioned: false,
        location:"Sommariva del Bosco, Cuneo",
        projectType:<>
            Interior Design project
            <br/>
            Styling project</>,
        photographer:"Ph. Letizia Cigliutti",
        styleStudio:"Graphic design and AD Bianca Previato Schiesari",
        preview: "brajafarm_1_orizzontale.jpg",
        cover: "brajafarm_1_orizzontale.jpg",
        vertical: "brajafarm_2_verticale.jpg",
        images: [
            {img:"brajafarm_3_verticale.jpg", cols: 1},
            {img:"brajafarm_4_verticale.jpg", cols: 1}
        ]
    },
    {
        id: "byro",
        name: "Byro",
        description: "",
        url: "",
        year: "2023",
        mentioned: false,
        location:"Fashion Design Studio, Milan",
        projectType:"Interior Design project",
        photographer:"Renders Yuri Meroni",
        styleStudio:"",
        preview: "byro_1_orizzontale.jpg",
        cover: "byro_1_orizzontale.jpg",
        vertical:"",
        images: []
    },
    {
        id: "sidequest",
        name: "Sidequest",
        description: "",
        url: "",
        year: "2022",
        mentioned: false,
        location:"Gaming cafè, London",
        projectType:"Interior Design project",
        photographer:"Progetto in collaborazione con Tianzhou Chen",
        styleStudio:"Renders Tianzhou Chen",
        preview: "sidequest_1_orizzontale.jpg",
        cover: "sidequest_1_orizzontale.jpg",
        vertical:"sidequest_2_verticale.jpg",
        images: [
            {
                img: "sidequest_3_quadrata.jpg",
                cols: 2
            }
        ]
    },
]