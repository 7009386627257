import React, {useEffect, useState} from "react";
import {Main} from "../layouts/Main";
import {Progetti} from "../components/Progetti";
import {Studio} from "../components/Studio";
import {scroll} from "../functions/scroll";
import {Backdrop, Box, Dialog, Fade, Stack, Typography, useTheme} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade appear={false} ref={ref} {...props} />;
});

export const Home = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        document?.documentElement && scroll('header')
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <Box>
            <Backdrop transitionDuration={1000} TransitionComponent={Transition} open={loading} sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Stack sx={{width:'100vw', height:'100vh', background:'white !important', zIndex:99}} justifyContent={'center'} alignItems={'center'}>
                    <Box>
                        <Typography textAlign={'center'} fontFamily={'Byrd'} variant={'h2'} fontWeight={'normal'}>
                            BLEND
                        </Typography>
                        <Typography textAlign={'center'} fontFamily={'Byrd'} variant={'h4'}>
                            STUDIO
                        </Typography>
                    </Box>
                </Stack>
            </Backdrop>
            <Main>
                <Progetti/>
                <Studio/>
            </Main>
        </Box>
    )
}
