import React from "react";
import {
    AppBar,
    Box, Collapse, Dialog, IconButton,
    Stack,
    Toolbar,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {ArrowBack, Close, DragHandle} from "@mui/icons-material";
import {scroll} from "../functions/scroll"
import {Contatti} from "../components/Contatti";
import {Link, useLocation} from "react-router-dom";

const pages = [
    {
        title: "Projects",
        to: "projects",
    },
    {
        title: "Studio",
        to: "studio",
    },
    {
        title: "Contacts",
        to: "contacts",
    }
]

export const Main = ({children}) => {
    const [open, setOpen] = React.useState(false);
    const location = useLocation()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box id={'header'}>
            <AppBar position={'fixed'}>
                <Toolbar sx={{position: 'relative', zIndex: 98}}>
                    <Stack direction={'row'} justifyContent={'space-between'}
                           sx={{width: '100%', py: 2, px: {xs: 0, md: 7}}} alignItems={'start'}>
                        <Box sx={{pt: {xs:3.8, md:2.5}}}>
                            <Typography fontFamily={'Byrd'} variant={'h3'} component={'a'} href={'/'} sx={{color:'black', textDecoration:'none'}}>
                                BLEND
                            </Typography>
                        </Box>
                        <Box sx={{textAlign:'end'}}>
                            {location.pathname !== '/' ?
                                <Box sx={{ pt: {xs:1.5, md:2.5}}}>
                                    <Box sx={{fontSize: '3rem', cursor:'pointer',mr:'-0.8rem',height:'6rem', textDecoration:'none'}} component={Link} to={'/'}>
                                        <ArrowBack sx={{fontSize:'inherit', color:'black', textDecoration:'none'}}/>
                                    </Box>
                                </Box>
                                :
                                <Box sx={{fontSize: {xs:"4rem", md:'5rem'}, pt:{xs:1.2, md:0}, color:'black', mr:'-0.8rem',height:'6rem', cursor:'pointer'}} onClick={() => setOpen(!open)}>
                                    {open ? <Close sx={{fontSize: 'inherit'}}/> : <DragHandle sx={{fontSize: 'inherit'}}/>}
                                </Box>
                            }
                            <Collapse in={open}
                                      sx={{
                                          background: 'white',
                                          zIndex: 99,
                                          right: 0,
                                          display: {xs: 'none', md: 'block'}
                                      }}>
                                <Box sx={{px: 3,pt:4}}>
                                    <Stack direction={'row'} spacing={{xs: 3, md: 10}}>
                                        {
                                            pages.map((p, i) => (
                                                <Box key={i} onClick={() => scroll(p.to)}
                                                     sx={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}
                                                >
                                                    <Typography variant={'h5'} textTransform={'uppercase'} className={'testo-menu'} fontWeight={'bold'}>
                                                        <span className={'parentesi-menu'}>(</span>
                                                        {p.title}
                                                        <span className={'parentesi-menu'}>)</span>
                                                    </Typography>
                                                </Box>
                                            ))
                                        }
                                    </Stack>
                                </Box>
                            </Collapse>
                        </Box>
                    </Stack>
                    {matches && <Dialog fullScreen open={open} onClose={() => setOpen(false)}
                             sx={{display: {xs: 'block', md: 'none'}}}>
                        <Toolbar>
                            <Stack direction={'row'} justifyContent={'space-between'}
                                   sx={{width: '100%', py: 2, px: {xs: 0, md: 2}}} alignItems={'start'}>
                                <Box sx={{pt: {xs:3.8, md:2.5}}}>
                                    <Typography fontFamily={'Byrd'} variant={'h3'} component={'a'} href={'/'} sx={{color:'black', textDecoration:'none'}}>
                                        BLEND
                                    </Typography>
                                </Box>
                                <Box sx={{fontSize: '5rem', color: 'black', mr:'-0.8rem'}} onClick={() => setOpen(!open)}>
                                    <Close sx={{fontSize: 'inherit'}}/>
                                </Box>
                            </Stack>
                        </Toolbar>
                        <Stack alignItems={'center'} spacing={10} sx={{pt: 10}}>
                            {
                                pages.map((p, i) => (
                                    <Box key={i} onClick={() => {
                                        setOpen(false)
                                        scroll(p.to)
                                    }}
                                         sx={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>
                                        <Typography variant={'h4'} textTransform={'uppercase'}  fontWeight={'bold'}>
                                            {p.title}
                                        </Typography>
                                    </Box>
                                ))
                            }
                        </Stack>
                    </Dialog>}
                </Toolbar>
            </AppBar>
            <Box sx={{ margin: 'auto', px: {xs:2, sm:3, md:10}, pb: 8}}>
                {children}
                <Contatti/>
            </Box>
        </Box>
    )
}
