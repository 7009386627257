import React from "react";
import {Box, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {scroll} from "../functions/scroll";

export const Contatti = () => {
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const contacts = [
        {
            title: "Address",
            text: <Typography variant={md ? 'h6' : 'body1'} fontWeight={'300'}>Via Filippino Lippi 41,<br/>Milan, 20131</Typography>,
            cols: {
                xs: 12,
                md: 4
            }
        },
        {
            title: "Contacts",
            text: <Typography variant={md ? 'h6' : 'body1'} fontWeight={'300'}>
                <a href={'mailto:info@studioblend.it'} target={"_blank"}
                   style={{color: 'black', textDecoration: 'none'}} rel="noreferrer">info@studioblend.it</a>
                <br/>
                +39 02 5182 9770
            </Typography>,
            cols: {
                xs: 6,
                md: 4
            }
        },
        {
            title: "Follow us on",
            text: <Typography variant={md ? 'h6' : 'body1'}>
                <Typography variant={md ? 'h6' : 'body1'} fontWeight={'300'} component={'a'}
                            href={'https://instagram.com/studioblend__?igshid=MzMyNGUyNmU2YQ=='} target={'_blank'}
                            sx={{textDecoration: 'none', color: 'black'}}>
                    Instagram
                </Typography>
                <br/>
                <Typography variant={md ? 'h6' : 'body1'} fontWeight={'300'} component={'a'}
                            href={'https://it.linkedin.com/company/studioblendmilano'} target={'_blank'}
                            sx={{textDecoration: 'none', color: 'black'}}>
                    Linkedin
                </Typography>
            </Typography>,
            cols: {
                xs: 6,
                md: 4
            }
        }
    ]
    
    return (
        <Stack sx={{height:'max(40rem, 100vh)'}} id={'contacts'} justifyContent={'end'}>
            <Grid container alignItems={'end'}>
                <Grid item xs={11} md={11.7}>
                    <Grid container spacing={4}>
                        {
                            contacts.map((c, i) =>
                                <Grid item key={i} xs={c.cols.xs} md={c.cols.md}>
                                    <Box>
                                        <Typography variant={md ? 'h6' : 'body1'} fontWeight={'300'} sx={{mb: {xs:1, md:1.5}}}>
                                            {c.title}
                                        </Typography>
                                        {c.text}
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={1} md={0.3}>
                    <Box onClick={() => scroll('header')} sx={{cursor:'pointer'}}>
                        <img src={require('../assets/images/shared/arrow.png')} style={{width:'100%'}}/>
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    )
}
