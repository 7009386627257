import {createTheme, responsiveFontSizes} from "@mui/material";

export const studioBlendTheme = responsiveFontSizes(createTheme({
    typography:{
      fontFamily: ["Replica", "sans-serif"].join(',')
    },
    components:{
      MuiAppBar:{
          styleOverrides:{
              root:{
                  background:'white',
                  color:'black',
                  boxShadow:'none'
              }
          }
      }
    },
    palette: {
        primary: {
            main: '#000000'
        }
    }
}))
