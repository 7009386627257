import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";

export const Studio = () => {
    return (
        <Grid container id={'studio'} spacing={{xs:3, md:10}}>
            <Grid item xs={12} md={5}>
                <img alt={'team'} src={require('../assets/images/team.jpg')} style={{width: '100%'}}/>
                <Typography fontWeight={'300'} lineHeight={'1.2'} sx={{pt:1, pb:{xs:1, md:0}}}>
                    Ph. Nicolò Parsenziani
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack justifyContent={'space-between'} sx={{height:'100%'}}>
                    <Typography fontWeight={'300'} lineHeight={'1.2'}>
                        Da un’amicizia fatta di terre diverse nasce Studio Blend.
                        <br/>
                        Blend è uno spazio fluido per la progettazione, nato dalla volontà
                        di non appartenere ad una categoria predefinita e dall’idea di unire
                        la passione per la ricerca estetica, l’arte e l’architettura.
                        Fondato nel 2022 da Patrizia Manconi, Cecilia Perotti e Sara Cerboneschi, Blend si occupa di
                        progetti di architettura, interni, arredo e scenografia, ponendosi come obbiettivo quello di
                        confondere insieme questi elementi. L’architettura si compone di spazi interni ed esterni, che tra
                        di loro
                        si compenetrano creando scenografie suggestive, che cambiano attraverso le prospettive di chi le
                        attraversa.
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}
